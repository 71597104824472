import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CollapseBorderBox } from '../common/CollapseBorderBox';
import CollapseBox from '../common/CollapseBox';
import './Questions.css'

const Questions = () => {


  //   {
  //     name: 'Sobre Tiim',
  //     questions: [
  //       {
  //         question: '¿Cómo funciona Tiim? ',
  //         answer: 'Tiim es una aplicación diseñada para ayudar a los padres (casados o divorciados) a organizarse mejor cuando tienen hijos. Hemos creado una solución que te permite tomar el control de los asuntos de tu familia relacionados con su salud, documentos, finanzas, seguros, viajes y compras, además de tener la posibilidad de compartir perfiles, eventos de calendario y gastos con otros miembros de la familia.'
  //       },
  //       {
  //         question: '¿Cómo me puedo registrar en Tiim? ',
  //         answer: '¡Muy fácil! Descarga la app de Tiim en el App Store o Google Play y regístrate con tu cuenta de Google, Apple o si prefieres ingresando tu correo electrónico.'
  //       },
  //       {
  //         question: '¿Necesito crear un usuario y contraseña? ',
  //         answer: 'No te pediremos una contraseña en tu registro, puedes ingresar con tu cuenta de Google, Apple o correo electrónico, que será lo único que validaremos para crear tu cuenta.'
  //       },
  //       {
  //         question: '¿A cuántos familiares puedo registrar? ',
  //         answer: [
  //           'Dependerá del plan de suscripción que contrates de acuerdo a las necesidades de tu familia:',
  //           'Plan Familiar: puedes registrar hasta 5 perfiles familiares + 3 mascotas y compartir perfiles, calendario y gastos hasta con 5 personas.',
  //           'Plan Familiar Plus: puedes registrar hasta 10 perfiles familiares + 5 mascotas y compartir perfiles, calendario y gastos hasta con 10 personas.'
  //         ],
  //       },
  //       {
  //         question: '¿Los padres necesitan contratar planes separados? ',
  //         answer: [
  //           'No, el usuario principal es quién registra la cuenta familiar. Al invitar a otros familiares a compartir la información, estos recibirán un correo con las instrucciones para que puedan ingresar a Tiim registrándose con ese mismo correo. ',
  //           'Si un invitado quiere compartir algún perfil o sección con alguien más, esto dependerá del número de perfiles disponibles según el Plan contratado y se le notificará al usuario principal al momento de confirmar la nueva invitación.'
  //         ]
  //       },
  //       {
  //         question: '¿Tiim funciona solo para hijos? ',
  //         answer: 'No, puedes registrar también tu perfil, el de tus hijos, pareja, padres o quién tu quieras. Además de poder registrar también a tus mascotas.'
  //       },
  //       {
  //         question: '¿Tiim es solo para padres divorciados? ',
  //         answer: 'No, Tiim es una aplicación diseñada para ayudar principalmente a los padres de familia (casados o divorciados) a organizarse mejor cuando tienen hijos.'
  //       },
  //       {
  //         question: '¿Puedo registrar también a mi mascota? ',
  //         answer: 'Sí, el número de mascotas que puedas subir a tu Tiim dependerá del plan que contrates.'
  //       },
  //       {
  //         question: '¿Tengo que pagar una suscripción para usar Tiim? ',
  //         answer: [
  //           'Si, actualmente tenemos dos tipos de suscripciones:',
  //           'Plan Familiar: puedes registrar hasta 5 perfiles familiares + 3 mascotas y compartir perfiles, calendario y gastos hasta con 5 personas.',
  //           'Plan Familiar Plus: puedes registrar hasta 10 perfiles familiares + 5 mascotas y compartir perfiles, calendario y gastos hasta con 10 personas.',
  //           'En cualquier plan, tienes 30 días GRATIS para usar la app y podrás cancelar tu suscripción en cualquier momento.'
  //         ]
  //       },
  //       {
  //         question: '¿Tiim es una app mundial? ',
  //         answer: 'Si, cualquier persona de cualquier país puede utilizar la app.'
  //       },
  //       {
  //         question: '¿Cómo puedo editar mis datos como usuario principal? ',
  //         answer: 'En la sección de Mi cuenta, tienes que ingresar en Usuario principal y ahí podrás editar tus datos.'
  //       },
  //       {
  //         question: '¿Cómo elimino mi cuenta? ',
  //         answer: 'En la sección de Mi Cuenta, en temas adicionales encontrarás el botón para eliminar cuenta o cerrar sesión.'
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Perfiles Familiares',
  //     questions: [
  //       {
  //         question: '¿Qué funcionalidades ofrece Tiim para organizar mejor a mi familia?  ',
  //         answer: [
  //           'Al registrar el Tiim de tu familia, puedes tener mejor organizado en los asuntos más importantes de tu familia:',
  //           'Salud: Gestiona el historial médico, información sobre doctores, medicamentos, alergias, enfermedades, antecedentes familiares, entre otros aspectos relacionados con la salud de tus seres queridos.',
  //           'Finanzas: Mantén un registro de información básica sobre los seguros, inversiones, cuentas bancarias, propiedades y vehículos, todo en un solo lugar.',
  //           'Documentos: Almacena tus documentos oficiales, actas de nacimiento, certificados de matrimonio y licencias de conducir. Además, recibirás notificaciones sobre fechas de vencimiento de documentos clave.',
  //           'Viajes: Organiza tus documentos de viaje, como pasaportes y visas, y lleva un control de programas de lealtad de aerolíneas, renta de coches, cruceros, hoteles y más.',
  //           'Compras: Crea listas de compras, consulta tallas para tus hijos, registra cupones y guarda los recibos de tus compras familiares.',
  //         ]
  //       },
  //       {
  //         question: '¿Puedo compartir los perfiles de mi familia? ',
  //         answer: 'Sí claro, puedes compartir los perfiles de tus hijos o mascotas con otros miembros de la familia, como tu pareja o ex-pareja, abuelos o cuidadores o quién tú quieras.',
  //       },
  //       {
  //         question: '¿Cómo funciona lo de compartir con familiares? ',
  //         answer: 'Cuando tienes tu Tiim, puedes compartir los perfiles de tus hijos o mascotas, el calendario y los gastos con otros miembros de la familia. Ellos recibirán un correo con las instrucciones para registrarse y tener acceso a la misma información.'
  //       },
  //       {
  //         question: '¿Qué pasa si yo edito la información del perfil de mis hijos? ',
  //         answer: 'Si editas cualquier información y tienes compartidos perfiles, calendarios o gastos con otros miembros de su familia, ambas cuentas se actualizarán en tiempo real y tendrán acceso a la misma información.'
  //       },
  //       {
  //         question: '¿Cuáles son las notificaciones que me envían?',
  //         answer: 'Recibes notificaciones de eventos en tu calendario, gastos compartidos y los vencimientos de documentos importantes como pasaportes, visas, licencias de conducir, seguros, etc.'
  //       },
  //       {
  //         question: '¿Cómo edito o elimino un perfil de mi cuenta? ',
  //         answer: 'Al entrar a cada perfil, junto al nombre encontrarás un icono de lápiz que al darle clic, podrás eliminar o editar la información básica de ese perfil.'
  //       },
  //       {
  //         question: '¿Qué pasa si quiero agregar a mas perfiles en mi cuenta pero ya registré el máximo de mi plan? ',
  //         answer: 'Puedes cambiar de plan en cualquier momento, y si ya estas en el Plan Plus y necesitas más perfiles, nos puedes contactar a hello@tiimapp.com y con mucho gusto te podemos habilitar más espacio.'
  //       },
  //     ]
  //   },
  //   {
  //     name: 'Calendario compartido',
  //     questions:
  //       [
  //         {
  //           question: '¿Cómo agrego un nuevo evento a mi calendario? ',
  //           answer: 'En la parte superior derecha, hay un botón con el signo de +, al darle clic, podrás crear un evento con la fecha, horario, alarma, etc.'
  //         },
  //         {
  //           question: '¿Puedo compartir los eventos? ',
  //           answer: 'Sí, puedes compartir los eventos con otros miembros de tu familia, en caso de que no estén todavía dados de alta, ahí mismo podrás invitarlos.'
  //         },
  //         {
  //           question: '¿Si quiero compartir un evento con un invitado nuevo, cómo le avisan? ',
  //           answer: 'Al invitar a un miembro de tu familia, les llegará un correo con las instrucciones para descargar la app, registrarse y tener acceso al mismo calendario.'
  //         },
  //         {
  //           question: '¿Cómo funciona los colores del tag? ',
  //           answer: 'Al crear un evento, puedes definir el color del tag, el cual será más fácil para identificar el evento en tu calendario.'
  //         },
  //         {
  //           question: '¿Si borro un evento compartido, le notifican a la otra persona? ',
  //           answer: 'Sí, incluso en la sección de mensajes en el calendario, saldrá el evento cancelado.'
  //         },
  //         {
  //           question: '¿Puedo agregar eventos al calendario en otras secciones de la app? ',
  //           answer: 'Sí, puedes agregar eventos o citas en otras secciones de la app como en Doctores y Vacunas. '
  //         },
  //         {
  //           question: '¿Puedo importar mis eventos de otro calendario? ',
  //           answer: 'Por el momento no, estamos trabajando para que en futuras actualizaciones puedas sincronizar otros calendarios.'
  //         },
  //       ]
  //   },
  //   {
  //     name: 'Administración de gastos',
  //     questions:
  //       [{
  //         question: '¿Cómo funciona la sección de gastos?',
  //         answer: 'En esta sección puedes organizar los gastos compartidos, definir porcentajes y subir tus recibos o comprobantes de pago, lo que facilita la transparencia financiera para toda tu familia.'
  //       },
  //       {
  //         question: '¿Puedo compartir los gastos con mas personas?',
  //         answer: 'Sí, puedes invitar a otras personas o miembros de tu familia para compartir gastos y organizarte mejor.'
  //       },
  //       {
  //         question: '¿Cómo se ven en la app los gastos que están pendientes por saldar?',
  //         answer: 'En el home de la sección de gastos, puedes ver tu balance actual y últimos gastos. Aquí podrás ver cuales son los gastos que están saldados (ícono verde) y los que no están pendientes por saldar (ícono gris). Al darle clic a cada gasto, podrás ver el detalle y actualizar el estatus.'
  //       },
  //       {
  //         question: '¿Si ya realice el pago pendiente, cómo lo actualizo en la app?',
  //         answer: 'Puedes darle clic en el gasto y en el detalle, encontrarás un botón que dice “Marcar como saldado”.'
  //       },
  //       {
  //         question: '¿Puedo dividir el gasto según un porcentaje?',
  //         answer: 'Sí, puedes dividir el gasto con porcentajes que van desde el 5% hasta el 100% en múltiplos de 5.'
  //       },
  //       {
  //         question: '¿Puedo subir el comprobante de pago como referencia del gasto?',
  //         answer: 'Sí, asl agregar un nuevo gasto, podrás subir archivos adjuntos como una foto, pdf o imagen con el comprobante o recibo de pago.'
  //       },
  //       {
  //         question: '¿Hay forma de ver un reporte de los gastos?',
  //         answer: 'Sí, puedes ver el reporte de gastos al darle clic en el ícono de gráfica que encontrarás en la parte superior izquierda. Aquí podrás ver el reporte de gastos por mes, familia o gastos que están pendientes por saldar.'
  //       },
  //       ]
  //   },
  //   {

  //     name: 'Seguridad y datos',
  //     questions: [

  //       {
  //         question: '¿Si subo a Tiim mis datos y los de mi familia, están seguros?',
  //         answer: 'Tu seguridad y la de tu familia es nuestra máxima prioridad. Hemos desarrollado tecnología con un alto nivel de seguridad, estrictos controles y políticas de protección de datos para mantener tu información segura y 100% confidencial. Nuestras bases de datos están encriptadas y cuentan con una infraestructura basada en tecnología tipo Blockchain para garantizar la seguridad en el intercambio de información digital y proteger tus datos de posibles actividades o ataques maliciosos.'
  //       },
  //       {
  //         question: '¿Comparten la información para fines comerciales?',
  //         answer: 'No, tu información no se comparte con propósitos comerciales en ningún momento. Tu información es privada y 100% confidencial.'
  //       },
  //       {
  //         question: '¿Cómo protegen la información de ataques o hackers?',
  //         answer: 'Creamos una infraestructura basada en tecnología tipo Blockchain para garantizar la seguridad en el intercambio de información digital y proteger tus datos de posibles actividades o ataques maliciosos.'
  //       },
  //       {
  //         question: '¿El equipo de Tiim tiene acceso a nuestra información?',
  //         answer: 'No, el equipo de Tiim en ningún momento tendrá acceso a la información de nuestros usuarios. La única información que guardamos es el correo electrónico y las fechas de ciertos documentos que necesitamos para notificar su vencimiento.'
  //       },
  //       {
  //         question: '¿Ustedes almacenan los datos de pago?',
  //         answer: 'No, el pago de las suscripciones se realizan por medio de las tiendas, ya sea en App Store o Google Play, por lo tanto, nosotros no tenemos acceso a tu información.'
  //       },
  //       {
  //         question: '¿Si cambio de celular pierdo mi información?',
  //         answer: 'No, tu cuenta está ligada a tu correo electrónico, por lo que si cambias de celular, deberás de ingresar con tu correo electrónico y validar tu usuario. '
  //       },
  //       {
  //         question: '¿En qué servidor almacenan las bases de datos?',
  //         answer: 'Nuestras bases de datos están descentralizadas y encriptadas. Nuestra infraestructura está hospedada en Google Firebase, que cuenta con los estándares más altos de calidad para la protección de datos y seguridad a nivel internacional.'
  //       },
  //       {
  //         question: '¿Puedo recomendar la app con mis amigos?',
  //         answer: 'Sí, puedes recomendar la app con tus amigos enviando un mensaje por correo electrónico, WhatsApp o redes sociales. Sólo tienes que ingresar a Mi Cuenta y darle clic en Recomendar a amigos y seleccionar el medio para hacerlo.'
  //       },
  //       {
  //         question: '¿Puedo subir la información de mi familia desde el sitio web?',
  //         answer: 'No, por el momento únicamente puedes subir información desde la app. Próximamente lo podrás hacer desde el sitio web.'
  //       },
  //       {
  //         question: '¿A dónde me puedo comunicar si tengo algún problema?',
  //         answer: 'Si tienes alguna duda o problema, escríbenos a hello@tiimapp.com y con mucho gusto te ayudaremos a resolverlos.'
  //       },
  //     ]
  //   },

  //   {
  //     name: 'Planes de suscripción',
  //     questions: [
  //       {
  //         question: '¿Cuáles son los planes de suscripción en Tiim?',
  //         answer: [
  //           'Tenemos dos planes de suscripción diseñados de acuerdo a las necesidades de cada familia:',
  //           'Plan Familiar: puedes registrar hasta 5 perfiles familiares + 3 mascotas y compartir perfiles, calendario y gastos hasta con 5 personas.',
  //           'Plan Familiar Plus: puedes registrar hasta 10 perfiles familiares + 5 mascotas y compartir perfiles, calendario y gastos hasta con 10 personas.',
  //           'En cualquier plan, tienes 30 días GRATIS para usar la app y podrás cancelar tu suscripción en cualquier momento.'
  //         ]
  //       },
  //       {
  //         question: '¿Cuál es el método de pago de la suscripción?',
  //         answer: 'El pago de cualquier plan de suscripción se realiza por medio de las tiendas, ya sea en App Store o Google Play.'
  //       },
  //       {
  //         question: '¿Puedes contratar la suscripción de forma mensual?',
  //         answer: 'No, por el momento el pago de cualquier suscripción es anual.'
  //       },
  //       {
  //         question: '¿Tienen que pagar ambos padres?',
  //         answer: 'No es necesario que ambos padres paguen la suscripción anual si es que todos los miembros de la familia pueden registrarse dentro del Plan Familiar o Plan Familiar Plus.'
  //       },
  //       {
  //         question: '¿Si yo pago la suscripción, soy el administrador?',
  //         answer: 'Si, la persona que se registra y paga por la suscripción, es el administrador o usuario principal de la cuenta en Tiim.'
  //       },
  //       {
  //         question: '¿Si cancelo la renovación de la suscripción, hay devoluciones?',
  //         answer: 'No, por el momento no hay devoluciones por el tiempo no usado en la contratación de la suscripción.'
  //       },
  //       {
  //         question: '¿Cómo funcionan los cupones?',
  //         answer: 'En el momento de seleccionar un pago de suscripción, puedes ingresar un cupón para que se te aplique el descuento correspondiente.'
  //       },
  //       {
  //         question: '¿Tengo que tener cuenta en el store para ponder contratar la suscripción?',
  //         answer: 'Si, es necesario tener una cuenta en iTunes o Google Play con cualquier método de pago registrado en esa cuenta.'
  //       },
  //       {
  //         question: '¿Puedo cancelar el pago dentro de los 30 días de prueba?',
  //         answer: 'Si, puedes cancelar tu suscripción en cualquier momento. Después de la prueba gratuita, se te cobrará a tu cuenta de iTunes o Google Play el plan que elijas.'
  //       },
  //       {
  //         question: '¿Cómo cancelo la suscripción?',
  //         answer: 'Las suscripciones y renovaciones automáticas se pueden desactivar dentro de su cuenta de iTunes o Google Play después de la compra. Cualquier parte no utilizada de la prueba gratuita se perderá después de la compra.'
  //       }
  //     ]
  //   }
  // ];
  const { t } = useTranslation();

  const [open, setOpen] = useState(isMobile ? null : 0)
  const [openBox, setOpenBox] = useState(null)

  return (
    <div className='faq-question '>
      <div className='faq-question-content'>
        <div className='left'>
          <div className='column_box'>
            {
              t(`faq.list`).map(({ name, icon, questions }, i) => {
                return <CollapseBox
                  open={open === i}
                  setOpen={() => { setOpen(isMobile ? (i === open ? null : i) : i); setOpenBox(null) }}
                  name={name}
                  key={`faq_${i}`}
                >
                  {
                    <div key={`faqs_${i}`} className={`faqs_panel ${open === i ? 'visible' : 'no_visible'}`}>
                      {questions.map(({ question, answer }, key) => (
                        <div key={key}>
                          <p className='border_box_title'>{question}</p>
                          <p className='description'>{answer}</p>
                        </div>
                      ))}
                    </div>
                  }
                </CollapseBox>
              })
            }
          </div>
        </div>
        <div className='right desktop'>
          {
             t(`faq.list`).map(({ questions }, i) => (
              <div key={`faqs_${i}`} className={`faqs_panel ${open === i ? 'visible' : 'no_visible'}`}>
                {questions.map(({ question, answer }, i) => (
                  <CollapseBorderBox
                    key={`question ${i}`}
                    title={question}
                    text={answer}
                    openBox={openBox === i}
                    setOpenBox={() => {
                      setOpenBox(i === openBox ? null : i)
                    }}
                  />
                ))}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Questions