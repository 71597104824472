import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images'
import './AboutInfo.css'

const AboutInfo = () => {
  const { t } = useTranslation();

  return (
    <div className='about-info'>
      <div className='about-container'>
        {/* <img loading='lazy' src={Images.tiim.createTiim} alt='' /> */}
        <div className='left desktop'>
          <img loading='lazy' className='' src={Images.aboutUs.family} alt="" />
        </div>
        <div className='right '>
          <div className='right-text'>
            <h2 className='subtitle'>{t(`about.info.subtitle`)}
              <img loading='lazy' src={Images.tiim.logo} alt='tiim' />
            </h2>
            <h3 className='description-subtitle'>{t(`about.info.descriptionSub`)}</h3>
            <p className='description'>{t(`about.info.description1`)}</p>
            <p className='description'>{t(`about.info.description2`)}</p>
            <p className='description'>{t(`about.info.description3`)}</p>
            <p className='description'>{t(`about.info.description4`)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutInfo