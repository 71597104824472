import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AboutUs, Faq, Main, Privacy, Security, Suscription, UniversalLink } from '../component'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main/>} />
      <Route path="/nosotros" element={<AboutUs/>} />
      <Route path="/seguridad" element={<Security/>} />
      <Route path="/suscripciones" element={<Suscription/>} />
      <Route path="/preguntas-frecuentes" element={<Faq/>} />
      <Route path="/aviso-de-privacidad" element={<Privacy/>} />
      <Route path="/terminos-y-condiciones" element={<Privacy/>} />
      <Route path="/universal-link" element={<UniversalLink/>} />
    </Routes>
  )
}

export default PrivateRoutes