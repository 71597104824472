import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Images from '../../assets/images'
import './SecurityData.css'

const SecurityData = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToPage = (page) => {
    navigate(page)
  }
  return (
    <div className='data-security'>
      <div className='data-security-content'>
        <div className='text'>
          <h1 className='subtitle'>{t(`security.data.subtitle`)}</h1>
          <h2 className='description-subtitle'>{t(`security.data.descriptionSub`)}</h2>
          <p className='description'>{t(`security.data.description1`)}</p>
          <p className='description'>{t(`security.data.description2`)}</p>
          <Button className='show-button desktop'
          onClick={() => goToPage('/preguntas-frecuentes')}
          >
            {t(`security.data.button`)}
          </Button>
        </div>
        <div className='img-side desktop'>
          <img loading='lazy' className='security-man' src={Images.securtity.securityFamily} alt='family' />
        </div>
      </div>
    </div>
  )
}

export default SecurityData