import React from 'react'
import './Blockchain.css'
import { Button, Grid } from '@mui/material'
import Images from '../../assets/images'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Blockchain = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToPage = (page) => {
    navigate(page)
  }

  return (
    <div className='blockchain'>
      <Grid className='container' container >
        <Grid className='blockchain-container' container justifyContent="space-between" >
          <Grid item className='left-side'>
            <h2 className={`subtitle ${t(`main.blockchain.addClass`)}`}>{t(`main.blockchain.title`)}</h2>
            <h3 className='description-subtitle'>{t(`main.blockchain.subtitle`)}</h3>
            <img loading='lazy' className='blockchain-img mobile' src={`${Images.tiim.blockchain}?w=161&fit=crop&auto=format`} alt='' />
            <p className='description'>{t(`main.blockchain.description`)} </p>
            <p className='note'>{t(`main.blockchain.note`)}</p>
            <Button className='show-button'
            onClick={() => goToPage('seguridad')}
            >
              {t(`main.blockchain.button`)}
            </Button>
          </Grid>
          <Grid item className='right-side desktop' >
            <img loading='lazy' className='blockchain-img' src={`${Images.tiim.blockchain}?w=161&fit=crop&auto=format`} alt='' />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Blockchain