import React from 'react'
import Images from '../../assets/images'
import './FaqSuscription.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const FaqSuscription = () => {
  // let faqs = [
  //   {
  //     faq: '¿Para utilizar Tiim, tengo que pagar?',
  //     answ: 'Puedes descargarla gratis, pero para usarla deberás de registrarte y contratar un plan de suscripción. En cualquier plan tienes 30 días gratis para probarla antes de que se te cobre la anualidad.',
  //   },
  //   {
  //     faq: '¿Cómo pago la suscripción?',
  //     answ: 'El pago se hace desde la app de Tiim por medio de tu cuenta de iTunes o Google Play.',
  //   },
  //   {
  //     faq: '¿Cómo puedo cambiar mi Plan?',
  //     answ: 'Ingresas a tu Cuenta en Tiim, y en la pestaña de suscripciones puedes cambiar tu Plan.',
  //   },
  //   {
  //     faq: '¿Puedo cancelar mi suscripción?',
  //     answ: 'Sí, puedes cancelar tu suscripción en cualquier momento y la podrás seguir utilizando durante el resto del periodo de pago.',
  //   }
  // ]

  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToPage = (page) => {
    navigate(page)
  }

  return (
    <div className='faq-suscription'>
      <div className='faq-suscription-content'>
        <div className='img-side'>
          <img className='security-man desktop' src={Images.suscription.family4} alt='man' />
        </div>
        <div className='text'>
          <h2 className='subtitle'>{ t(`suscription.faqs.title`)}</h2>
          {
            t(`suscription.faqs.list`).map(({ faq, answ }, index) => (
              <div key={index}>
                <p className='description-subtitle'>{faq}</p>
                <p className='description'>{answ}</p>
              </div>
            ))
          }
          <Button className='show-button'
          onClick={() => goToPage('/preguntas-frecuentes')}
          >
           { t(`suscription.faqs.button`)} 
          </Button>
        </div>

      </div>
    </div>
  )
}

export default FaqSuscription