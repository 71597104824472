import React, { useEffect } from 'react'
import { Banner, Bills, CreateTiim, DownloadBar, Events, Opinions, Organize, Profile, Blockchain } from  '../../component';

const Main = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <Banner />
      <DownloadBar />
      <CreateTiim />
      <Organize />
       <Blockchain />
      <DownloadBar />
      <Bills />
      <Events />
      <Profile />
      <DownloadBar />
      <Opinions /> 
    </>
  )
}

export default Main