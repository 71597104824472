import React, { useEffect } from 'react'
import BannerSuscription from './BannerSuscription'
import Plans from './Plans'
import FaqSuscription from './FaqSuscription'
import {DownloadBar} from '../'

const Suscription = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <BannerSuscription />
      <Plans />
      <FaqSuscription/>
      <DownloadBar/>
    </div>
  )
}

export default Suscription