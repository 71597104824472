import React, { useState } from 'react'
import Images from '../../assets/images'
import './Plans.css'
import PriceBox from './PriceBox'
import IconList from '../common/IconList'
import { useTranslation } from 'react-i18next'
import Popover from '@mui/material/Popover';


const Plans = () => {
  const [plan, setPlan] = useState('annual');
  const { t } = useTranslation();

  const onClick = (value) => {
    setPlan(value)
  }


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'region-popover' : undefined;

  const [current, setCurrent] = useState('mx')
  const regions = t(`suscription.plans.regions`)

  return (
    <div className='plans'>

      <div className='plans-content'>
        <div>
          <div className='header-row'>
            <div className='switch-button'>
              <div className={`switch-option ${plan === 'annual' && 'active'}`} onClick={() => onClick('annual')}>
                <span>{t(`suscription.plans.annual`)}</span>
                <span className='label'>{t(`suscription.plans.save`)}</span>
              </div>
              <div className={`switch-option ${plan === 'monthly' && 'active'}`} onClick={() => onClick('monthly')}>
                <span>{t(`suscription.plans.monthly`)}</span>
              </div>
            </div>
            <div className='region' onClick={handleClick}>
              <img src={Images.flags[regions[current].flag]} alt='flag' />
              <span>{regions[current].name}</span>
            </div>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className='popper-region'>
                {
                  Object.values(regions).map(({ name, flag, type }, index) => (
                    <div key={index} className={`region-row ${type === current ? 'active' : ''}`}
                      onClick={() => { setCurrent(type);handleClose(); }}
                    >
                      <img className='check' src={Images.suscription.checka} alt='check' />
                      <img className='region-flag' src={Images.flags[flag]} alt='flag' />
                      {name}
                    </div>
                  ))
                }
              </div>
            </Popover>
          </div>
          <div className='plans-row'>
            {
              t(`suscription.plans.plansList`).map(({ name, annual, prices,  list, monthly, icon }, i) => (
                <PriceBox
                  key={i}
                  img={Images.suscription[icon]}
                  name={name}
                  price={plan === 'annual' ? prices[current].annual  : prices[current].monthly}
                  period={plan === 'annual' ? t(`suscription.plans.annualTag`) : t(`suscription.plans.monthlyTag`)}
                  list={list}
                  note={t(`suscription.plans.trial`)}
                />
              ))
            }
          </div>
        </div>
        <p className='note'>{t(`suscription.plans.note`, { variable: regions[current].currency })}</p>
      </div >
  <div className='plans-content-list'>
    <IconList
      list={t(`suscription.plans.list`)}
      icon={Images.icons.checkSquare}
    />
  </div>
    </div >
  )
}

export default Plans