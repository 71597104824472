import React from 'react'
import './Profile.css'
import { Grid } from '@mui/material'
import Images from '../../assets/images'
import { useTranslation } from 'react-i18next'

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid className='profile' >
        <div className='profile-container'>
          <img className='profile-screen desktop' src={Images.profile[t(`main.profile.screen`)]} alt="profiles" />
          <div className='text-profile' >
            <h2 className='subtitle'>{t(`main.profile.subtitle`)}</h2>
            <h3 className='description-subtitle'>{t(`main.profile.descriptionSub`)}</h3>
            <img loading='lazy' className='profile-screen mobile' src={Images.profile[t(`main.profile.screen`)]} alt="profiles" />
            <p className='description'>{t(`main.profile.description`)}</p>
            <p className='note'>{t(`main.profile.note`)}</p>
          </div>
        </div>
      </Grid>
    </>
  )
}

export default Profile