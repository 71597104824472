import React, { useEffect } from 'react'
import DownloadBar from '../DownloadBar'
import AntiHacker from './AntiHacker'
import BannerSecurity from './BannerSecurity'
import SecurityData from './SecurityData'
const Security = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  // eslint-disable-next-line
  }, [])
  return (
    <div>
      <BannerSecurity />
      <DownloadBar />
      <AntiHacker />
      <SecurityData />
      <DownloadBar />
    </div>
  )
}

export default Security