import { Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './BannerAbout.css'
import Images from '../../assets/images'
import { useTranslation } from 'react-i18next'

const BannerAbout = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(1)
  const messages = [
    {
      src: Images.aboutUs[t(`about.messageImg`)]
    },
    {
      src: Images.aboutUs[t(`about.messageImg`)]
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      let valor = Math.floor(Math.random() * (2)) + 1
      setMessage(valor)
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='about-us'>
      <div className='about-us-content'>
        <div className='text'>
          <h1 className='heading'>{t(`about.banner.heading`)}</h1>
          <h2 className='caption '>{t(`about.banner.caption`)}</h2>
        </div>
        <img loading='lazy' className='back' src={Images.aboutUs.dadAndKid}  alt='' />
        <div className='message desktop'>
          {
            messages.map(({ src }, item) => (
              <Slide key={`slide${item}`} direction="left" in={message === (item + 1)} mountOnEnter unmountOnExit
                {...(message === (item + 1) ? { timeout: 1000 } : {})}
              >
                <img loading='lazy' alt='' className='' src={src} />
              </Slide>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BannerAbout