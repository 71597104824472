import React from 'react'
import Images from '../../assets/images'
import { useTranslation } from 'react-i18next';
import './BannerSuscription.css'

const BannerSuscription = () => {
  const { t } = useTranslation();

  return (
    <div className='banner-suscription'>
      <div className='banner-suscription-content'>
        <div className='text'>
          <h1 className='heading'> {t(`suscription.banner.title`)} </h1>
          <h2 className='caption '>  {t(`suscription.banner.caption`)} <span>  {t(`suscription.banner.freeDays`)}  </span></h2>
        </div>
        <div className='img-side'>
          <img className='suscription-mom' src={Images.suscription.momAndKid} alt='man' />
        </div>
      </div>
    </div>
  )
}

export default BannerSuscription