import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images'
import './BannerFaq.css'

const BannerFaq = () => {
  const { t } = useTranslation();
  return (
    <div className='banner-faq'>
      <div className='banner-faq-content'>
        <div className='text'>
          <h1 className='heading'>{t(`faq.banner.heading`)} </h1>
          <h2 className='caption '>{t(`faq.banner.caption`)}</h2>
        </div>
        <div className='img-side'>
          <img loading='lazy' className='family-ask' src={Images.faq.familyAsk} alt='man' />
        </div>
      </div>
    </div>
  )
}

export default BannerFaq