import { Grid } from '@mui/material'
import React from 'react'
import Images from '../../assets/images'
import './CardBox.css'

const CardBox = ({ label, description, img, open, setOpen }) => {

  return (

    <Grid container flexDirection="row" className={`card  ${open ? 'selected' : ''}`}
      onClick={() => { setOpen() }}
    >
      <div className='carbox-content'>
        <img className='item-img' src={img} alt='' />
        <div className='card-text'>
          <h4>{label}</h4>
          <p className={`card-description desktop`}>{description}</p>
          <img className='mobile' src={Images.icons.downArrow} alt='' />
        </div>
      </div>

      <p className={`card-description mobile`}>{description}</p>

      {/* 
      <Grid md={3} item alignSelf="center" textAlign="center">
        <img src={img} alt='' />
      </Grid>
      <Grid md={9} item className='card-text'>
        <h4>{label}</h4>
        <p className='card-description'>{description}</p>
      </Grid> */}


    </Grid>
  )
}

export default CardBox