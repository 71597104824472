import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ModalVideo } from '..';
import Images from '../../assets/images';
import './Banner.css';

const Banner = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false)

  const messages = [
    {
      src: Images.messages[t(`main.banner.messageImg`)],
    },
    {
      src: Images.messages[t(`main.banner.messageImg`)],
    }
  ];

  const [message, setMessage] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      let valor = Math.floor(Math.random() * (2)) + 1
      setMessage(valor)
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className='container banner '>
      <div className='banner-bg'>
        <div className='text-banner'>
          <h1 className={`banner-heading heading ${t(`addClass`)}`}>{t(`main.banner.title`)}</h1>
          <h2 className={`banner-caption caption desktop ${t(`addClass`)}`}>{t(`main.banner.description`)}</h2>
          <div className='watch-video-container' onClick={() => setShowModal(true)}>
            <img className="play-icon" alt="" src={Images.icons.play} onClick={() => setShowModal(true)} />
            <p >{t(`main.banner.watchVideo`)}</p>
          </div>
        </div>
        <div className='message desktop'>
          {
            messages.map(({ src }, item) => (
              <Slide key={`slide${item}`} direction="left" in={message === (item + 1)} mountOnEnter unmountOnExit
                {...(message === (item + 1) ? { timeout: 1000 } : {})}
              >
                <img loading='lazy' alt='slide' src={src} />
              </Slide>
            ))
          }
        </div>
      </div>
      <h3 className='banner-caption caption mobile'>{t(`main.banner.description`)}</h3>
      <div className="wrap-modal-video">
        {showModal &&
          <ModalVideo
            Id={t(`videoID`)}
            setShowModal={setShowModal}
          />
        }
      </div>
    </div>
  )
}

export default Banner 