import React from 'react'
import Images from '../../assets/images'
import './Opinions.css'
import { OpinionCard } from '../../component';
import { useTranslation } from 'react-i18next';

const Opinions = () => {

  const { t } = useTranslation();

  const list = t(`main.opinion.list`)
  return (
    <div className="opinions">
      <div className='opinions-container'>
        <h1 className={`subtitle  ${t(`addClass`)}`}>{t(`main.opinion.subtitle`)}</h1>
        <div className="container-row">
          {
            list.map(({ name, occupation, opinion, image }, i) => {
              return (
                <OpinionCard
                  key={`opinion${i}`}
                  name={name}
                  occupation={occupation}
                  opinion={opinion}
                  image={Images.tiim[image]}
                />
              )
            })
          }
        </div>
      </div>

    </div >
  )
}

export default Opinions