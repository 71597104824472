import { Button, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Images from '../../assets/images'
import './Bills.css'
import { useTranslation } from 'react-i18next'

const Bills = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPage = (page) => {
    navigate(page)
  }

  return (
    <Grid className='bills'>
      <Grid container className='bills-container'>
        <Grid item xs={12} md={6}>
          <Grid container className='left-container' alignItems="center" >
            <img loading='lazy' className='desktop' src={Images.bills[t(`main.bills.screen`)]} alt="bills" />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className='right-container' >
          <Grid container  flexDirection="column" justifyContent="center" >
            <h2 className='subtitle'>{t(`main.bills.subtitle`)}</h2>
            <h3 className='description-subtitle'>{t(`main.bills.descriptionSub`)}</h3>
            <img loading='lazy' className='bills-mobile mobile' src={Images.bills[t(`main.bills.screen`)]} alt="bills" />
            <p className='description'>{t(`main.bills.description`)}</p>
            <p className='note'>{t(`main.bills.note`)}</p>
            <Button className='show-button'
            onClick={() => goToPage('suscripciones')}
            >
              {t(`main.bills.button`)}
            </Button>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Bills