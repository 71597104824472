import './ModalVideo.css'

const ModalVideo = ({ Id, setShowModal }) => (
    <div className="modal-video" onClick={()=>setShowModal(false)}>
        <div className="modal-video-body">
            <div className="inner" >
                <div className="modal-video-movie" >
                    <div className="modal_close_btn"  onClick={()=>setShowModal(false)} >
                    </div>
                    <div className="video-responsive">
                        <iframe
                            // width="853"
                            // height="480"
                            src={`https://www.youtube.com/embed/${Id}?autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="youtube_video"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

);

export default ModalVideo;