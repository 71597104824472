import React from 'react'
import { useNavigate } from 'react-router-dom';
import './ColumnList.css'
const ColumnList = ({ title, list }) => {
  const navigate = useNavigate();
  const path = (window.location.hash).replace('#/', '');

  const goToPage = (page) => {
    if (path === page)
      window.scrollTo(0, 0)
    navigate(page)
  }

  return (
    <div className="column_list" >
      <p>{title}</p>
      <ul>
        {
          list.map(({ name, onClick, url }, i) => {
            return (
              <li
                key={`${title}_${i}`}
                className="column_li"
                onClick={url ? () => (goToPage(url)) : onClick}
              >
                {name}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default ColumnList;
