import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images'
import './BannerSecurity.css'

const BannerSecurity = () => {
  const { t } = useTranslation();

  return (
    <div className='banner-security'>
      <div className='banner-security-content'>
        <div className='text'>
          <h1 className='heading'>{t(`security.banner.heading`)}</h1>
          <h2 className='note white'>{t(`security.banner.note`)}</h2>
        </div>
          <div className='right-side'>
            <img loading='lazy' className='security-man' src={Images.securtity.securityMan} alt='man' />
          </div>
      </div>
    </div>
  )
}

export default BannerSecurity