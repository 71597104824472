import React, { useEffect } from 'react'
import BannerFaq from './BannerFaq'
import {DownloadBar} from '../'
import Questions from './Questions'

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  // eslint-disable-next-line
  }, [])
  return (
    <div>
      <BannerFaq/>
      <DownloadBar/>
      <Questions/>
    </div>
  )
}

export default Faq