import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../i18n/en.json'
import es from '../i18n/es.json'

let idiomaNavegador = navigator.language.includes('es') ? 'es' : 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: { translation: es },
      en: { translation: en }
    },
    lng: idiomaNavegador, // if you're using a language detector, do not define the lng option
    fallbackLng: "es",
    returnObjects: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
  });