import React, { useEffect } from 'react'
import './AboutUs.css'
import {BannerAbout, DownloadBar, AboutInfo, AboutSecurity} from '../../component'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <BannerAbout/>
      <DownloadBar />
      <AboutInfo/>
      <AboutSecurity/>
      <DownloadBar />
    </div>
  )
}

export default AboutUs