import { Grid } from '@mui/material'
import React from 'react'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import Images from '../assets/images'
import './DownloadBar.css'
import { useTranslation } from 'react-i18next'

const DownloadBar = () => {
  const { t } = useTranslation();
  
  return (
    <Grid className='download-bar' container  justifyContent='center' alignItems="center">
      <p className='caption'>{t(`downloadBar.caption`)}{!isDesktop && <br/>} <span className='free-text'>{t(`downloadBar.freeDays`)}</span></p>
      {
        (isDesktop || isAndroid) && <img loading='lazy' src={Images.tiim.playstore} alt='playstore'  
        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.tiimapp&pcampaignid=web_share', "_blank")}
        />
      }
      {
       (isDesktop || isIOS) && <img loading='lazy' src={Images.tiim.appstore} alt='appstore' 
       onClick={() => window.open('https://apps.apple.com/mx/app/tiim/id6466341070', "_blank")}
       />
      }
    </Grid>
    
  )
}

export default DownloadBar