import { HashRouter } from 'react-router-dom';
import './App.css';
import { Footer, Header } from './component';
import PrivateRoutes from './router/PrivateRoutes';


function App() {
  return (
    <div className="App">
      <HashRouter>
        <header className="App-header">
          <Header />
        </header>
        <main className='content'>
          <PrivateRoutes />
        </main>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
