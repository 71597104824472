import React from 'react'
import './Footer.css'
import { ColumnList } from '../component'
import Images from '../assets/images'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation();

  const contact = [
    {
      name: "hello@tiimapp.com",
      onClick: () => window.location.href = "mailto:hello@tiimapp.com"
    }
  ]
  return (
    <div className="footer ">
      <div className="footer_list">
        <div className='info_list'>
          <img
            alt=''
            className='logo_white'
            src={Images.tiim.logoWhite}
          />
          <div className='desktop'>
            <p>{t(`footer.title`)}</p>
            <p>{t(`footer.subtitle`)}</p>
          </div>
        </div>
        <div className='desktop'>
          <ColumnList
            title="Links"
            list={t(`footer.links`)}
          />
        </div>
        <div className='desktop'>
          <ColumnList
            title={t(`footer.legalName`)}
            list={t(`footer.legal`)}
          />
        </div>
        <div className='contact' >
          <ColumnList
            title={t(`footer.contactName`)}
            list={contact}
          />
          <div className="rrss">
            <img
              alt="facebook"
              src={Images.icons.facebook}
              onClick={() => window.open(t(`footer.facebook`))}
            />
            <img
              alt="twitter"
              src={Images.icons.twitter}
              onClick={() => window.open(t(`footer.twitter`))}
            />
            <img
              alt="instagram"
              src={Images.icons.instagram}
              onClick={() => window.open(t(`footer.instagram`))}
            />
            <img
              alt="linkedin"
              src={Images.icons.linkedin}
              onClick={() => window.open(t(`footer.linkedin`))}
            />
          </div>
        </div>
      </div>
      <div className="copyright">
        <p><span className='mobile'>2023</span>© Tiim Global, Inc. {t(`footer.copyright`)}  <span className='desktop'>  2023</span></p>
      </div>
    </div>
  )
}

export default Footer