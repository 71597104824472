import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import Carousel from 'react-material-ui-carousel'
import Images from '../../assets/images'
import { CardBox } from '../../component'
import './Organize.css'
import { useTranslation } from 'react-i18next'

const Organize = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(null);
  useEffect(() => {
    if (isDesktop) {
      const interval = setInterval(() => {
        let valor = open === 4 ? null : (open === null ? 0 : open + 1);
        setOpen(valor)
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [open]);
  const items = t(`main.organize.items`);
  const screens = t(`main.organize.screens`);
  return (
    <div className='organize' >

      <div className='organize-content'>
        <div className='organize-text'>
          <h2 className='subtitle'>{t(`main.organize.title`)} </h2>
          <p className='description'>
            {t(`main.organize.description`)}
          </p>
        </div>
        <div className='organize-row'>
          <div className='left-row '  >
            {isMobile &&
              <Carousel height="670px" navButtonsAlwaysInvisible={true} swipe={false}>
                {screens.map((item, index) => (
                  <div key={index} className='screen-container '>
                    {index === 0 &&
                      <img loading='lazy' className={`crown_screen`} src={Images.organize.crown} alt='main_ss' />
                    }
                    <img loading='lazy' className={`screen`} src={Images.organize[item]} alt='main_ss' />
                  </div>
                ))}
              </Carousel>
            }
            <div className='screen-container desktop'>
              {open === null &&
                <img loading='lazy' className={`crown_screen`} src={Images.organize.crown} alt='main_ss' />
              }
              <img loading='lazy' className={`screen`} src={open === null ? Images.organize[t(`main.organize.screen1`)] : Images.organize[items[open].screen]} alt='main_ss' />
            </div>
          </div>

          <Grid className='cards-container'>
            {
              items.map(({ label, description, img, screen }, i) => (
                <CardBox
                  key={i}
                  label={label}
                  description={description}
                  img={Images.organize[img]}
                  screen={Images.organize[screen]}
                  open={open === i}
                  setOpen={() => setOpen(i === open ? null : i)}
                />
              ))
            }
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Organize