import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images'
import './AntiHacker.css'

const AntiHacker = () => {
  const { t } = useTranslation();

  return (
    <div className='antihacker-security'>
      <div className='antihacker-security-content'>
        <div className='img-side'>
          <img loading='lazy' className='security-man' src={Images.securtity.antiHacker} alt='man' />
        </div>
        <div className='text'>
          <h2 className='subtitle'>{t(`security.blockchain.subtitle`)}</h2>
          <h3 className='description-subtitle'>{t(`security.blockchain.descriptionSub`)}</h3>
          <p className='description'>{t(`security.blockchain.description`)}</p>
          <img loading='lazy' className='desktop' src={Images.securtity.sslIcon} alt='ssl'/>
        </div>
      </div>
    </div>
  )
}

export default AntiHacker