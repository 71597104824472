import { Button, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Images from '../../assets/images'
import './CreateTiim.css';
import { useTranslation } from 'react-i18next';

const CreateTiim = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToPage = (page) => {
    navigate(page)
  }
  return (
    <div className='create-tiim '>
      <Grid className='create-container' container justifyContent="center" >
        <Grid item xs={12} md={6} >
          <Grid className='left-content' container flexDirection="column" justifyContent="center" >
            <h2 className='subtitle'><span> {t(`main.create.title1`)} </span>
              <img loading='lazy' src={Images.tiim.logo} alt='tiim' />
              {t(`main.create.title2`)}</h2>
            <h3 className='description-subtitle'> {t(`main.create.subtitle`)} </h3>
            <img loading='lazy' className='mobile mobile-screen' src={Images.create[t(`main.create.screenMobile`)]} alt='' />
            <p className='description'> {t(`main.create.description`)} </p>
            <p className='note'>{t(`main.create.note`)}</p>
            <div className='button-container'>
              <Button className='show-button'
                onClick={() => goToPage('suscripciones')}
              >
                {t(`main.create.button`)}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid className='create-right-content' item xs={12} md={6}>
          <div className='desktop'>
            <img loading='lazy' src={Images.create[t(`main.create.screen`)]} alt='' />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateTiim