import React from 'react'
import './Events.css'
import { Grid } from '@mui/material'
import Images from '../../assets/images'
import { IconList } from '../../component'
import { useTranslation } from 'react-i18next'

const Events = () => {
  const { t } = useTranslation();
  const list = t(`main.events.list`);
  
  return (
    <Grid className='events'>
      <Grid container className='events-container' justifyContent="flex-end">
        <Grid item xs={12} md={6} className='left-container' >
          <Grid container flexDirection="column" justifyContent="center" >
            <h2 className='subtitle'>{t(`main.events.subtitle`)}</h2>
            <h3 className='description-subtitle'>{t(`main.events.descriptionSub`)}</h3>
            <img loading='lazy' className='calendar-screen mobile' src={Images.events[t(`main.events.screen`)]} alt="events" />
             <p className='description'>{t(`main.events.description`)} </p>
            <IconList
              list={list}
              icon={Images.icons.circleCheck}
            /> 
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container className='right-container desktop' alignItems="center" justifyContent="right" >
            <img loading='lazy' className='' src={Images.events[t(`main.events.screen`)]} alt="events" />
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
  )
}

export default Events