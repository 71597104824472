import React from 'react'
import './PriceBox.css'
import Images from '../../assets/images'

const PriceBox = ({ name, price, period, img, list, note  }) => {
  return (
    <div className='price-box'>
      <div className='price-box-child'>
        <div className='price-box-header'>
          <div className='header-box-left'>
            <img src={img} className='icon-price-box' alt='icon-start' />
            <span className='' >{name}</span>
          </div>
          <div className='header-box-right'>
            <span><strong>{price}</strong>/{period}</span>
            <span className='note-price' >{note}</span>
          </div>
        </div>
        <div className='price-box-content'>
          {
            list.map((description, index) => (
              <div key={index}>
                <img src={Images.suscription.checka} alt='check' />
                <span className='note-price' >{description}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default PriceBox