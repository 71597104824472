import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DownloadBar from '../DownloadBar';
import './Privacy.css';
const Privacy = () => {
  const { t } = useTranslation();
  const path = (window.location.hash).replace('#/', '');
  const [page, setPage] = useState('privacy')

  useEffect(() => {
    if (path === 'aviso-de-privacidad')
      setPage('privacy')
    else if (path === 'terminos-y-condiciones')
      setPage('terms')
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [path])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (path === 'aviso-de-privacidad')
      setPage('privacy')
    else if (path === 'terminos-y-condiciones')
      setPage('terms')
    // eslint-disable-next-line
  }, []);

  const getElement = ({underline, email, url}, key) => {
    if (underline) {
      return (
        <p key={key}>
          <u>{underline.name}</u>:
          <span>{underline.content}</span>
        </p>
      )
    }
    if(email){
      return(<p key={key} >
        {email.start}
        <span className='email-link' onClick={() => window.location.href = "mailto:hello@tiimapp.com"}>{email.link}</span>
        {email.end}
      </p>)
    }
    if(url){
      return(<p key={key} >
        {url.start}
        <span className='email-link' onClick={() => window.open(`https://${url.link}`)}>{url.link}</span>
        {url.end}
      </p>)
    }
  }

  return (
    <>
      <div className='legal-content'>
        <div className=' legal-parent '>
          <div className="update">
            <span>{t(`terms.updated`)}</span>
          </div>
          <div className='conditions-content'>
            <div className='text-container' id='text-container'>
              <h1>{t(`${page}.title`)}</h1>
              {
                t(`${page}.intro`) &&
                <div>
                  <div>
                    <div >
                      {
                        Array.isArray(t(`${page}.intro`)) ?
                        t(`${page}.intro`).map((item, i) => (
                            <p key={`intro_${i}`} >{item}</p>
                          ))
                          :
                          <p>{t(`${page}.intro`)}</p>
                      }
                    </div>
                    {
                      t(`${page}.clauses`) &&
                      t(`${page}.clauses`).map(({ title, content }, i) => (
                        <div key={`content_${i}`} className='text_block'>
                          <p><b>{title}</b></p>
                          {
                            Array.isArray(content) ?
                              content.map((item, i) => (
                                Array.isArray(item) ? (
                                  <ul key={`term_ul_${i}`}>
                                    {
                                      item.map((list_item, i) =>
                                        <li key={`term_li_${i}`}>
                                          {list_item}
                                        </li>
                                      )
                                    }
                                  </ul>
                                ) : (
                                  typeof item === 'string' ?
                                    <p key={`term_p_${i}`} >{item}</p>
                                  :
                                    getElement(item, `term_p_${i}`)
                                )
                              )) :
                              <p>{content}</p>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <DownloadBar />
    </>
  )
}

export default Privacy