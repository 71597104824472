import React from 'react'
import Images from '../../assets/images'
import './CollapseBox.css'

const CollapseBox = ({ name, open, setOpen, children }) => {
  // const { innerWidth: width } = window;
  return (
    <div className={`collapse_wrap ${(open ? "open_box" : "")}`}>
      <div
        className={"collapse_box " + (open ? "open_box" : "")}
        onClick={() => {
          setOpen()
        }}
      >
        <div className="main_box" >
          <p className=" description-subtitle">{name}</p>
          <img
            className="box_control"
            alt="open"
            src={open ? Images.icons.upArrow : Images.icons.downArrow}
            onClick={() => {
              setOpen()
            }}
          />
        </div>
        <div className={`mobile ${(open ? "open" : "")}`}>
          {children && children}
        </div>
      </div>
    </div>

  )
}

export default CollapseBox
