import { Button } from '@mui/material'
import React from 'react'
import Images from '../../assets/images'
import './AboutSecurity.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AboutSecurity = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToPage = (page) => {
    navigate(page)
  }
  return (
    <div className='about-security'>
      <div className='content'>
        <div className='text '>
          <img alt='shield' className='mobile about-shield' src={Images.aboutUs.shield} />
          <h3 className='description-subtitle '>{t(`about.securities.descriptionSub`)}</h3>
          <p className='description '>
            {t(`about.securities.description`)}
          </p>
          <div className='desktop'>
            <img alt='shield' className=' about-shield' src={Images.aboutUs.shield} />
            <p className='note'>{t(`about.securities.note`)}</p>
          </div>
          <div className='button-container desktop'>
            <Button className='show-button'
            onClick={() => goToPage('/seguridad')}
            >
              {t(`about.securities.button`)}
            </Button>
          </div>
        </div>
      </div>
      <div className='mobile'>
        <p className='note'>{t(`about.securities.note`)}</p>
        <div className='button-container'>
          <Button className='show-button'
          onClick={() => goToPage('/seguridad')}
          >
            {t(`about.securities.button`)}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AboutSecurity